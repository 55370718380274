import React from 'react'

const ReviewFooter = props => {
    return(
        <div id="review-footer" className={`${props.result ? "bg-green-100" : "bg-red-100"} md:text-lg`}>
            <div className="flex justify-between items-center p-10 h-20 md:container md:max-w-screen-lg md:mx-auto">
                <div className="abril">
                    {
                        props.result ?
                        "Your answer is correct." :
                        `Incorrect. Correct answer was "${props.correctAnswer}"`
                    }
                </div>
                <div>{props.result ? "✅" :"❌"}</div>
            </div>
        </div>    
    )
}

export default ReviewFooter