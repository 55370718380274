import React from 'react'
import { connect } from 'react-redux'

import Button from '../elements/Button'

import { startLogoutUser } from '../store/actions/auth'

const Header = props => {
    return (
        <div id="Header" className="pt-10 flex flex-row justify-between">
            <div
                id="logo"
                className="abril"
            >
                TOEFL Vocab<sup>α</sup>
            </div>
            {
                !props.homePage ?
                <div id="links-and-buttons">
                    <Button action={props.startLogoutUser} color="green" small text="Logout" />
                </div>
                : ""
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    startLogoutUser: () => dispatch(startLogoutUser())
})

export default connect(undefined, mapDispatchToProps)(Header)