import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

import { startRetrieveSet, startGetNumberOfDoneTests } from '../store/actions/database'

import ProgressBar from '../elements/ProgressBar'
import Option from '../elements/Option'
import LearnFooter from '../elements/LearnFooter'


const Question = props => {

    const testNumber = props.match.params.id - 1
    const uid = props.uid
    const retrieveSetFunction = props.startRetrieveSet
    const getNumberOfDoneTests = props.getNumberOfDoneTests
    const numberOfDoneTests = props.numOfDoneSets

    useEffect(() => {
        if (numberOfDoneTests === 0) {
            getNumberOfDoneTests(uid , () => {
                if (numberOfDoneTests >= testNumber) {
                    retrieveSetFunction(testNumber)
                }
            })
        } else {
            retrieveSetFunction(testNumber)
        }
    }, [retrieveSetFunction, uid, getNumberOfDoneTests, testNumber, numberOfDoneTests])
    
    if ((numberOfDoneTests && testNumber > props.numOfDoneSets) || testNumber > props.numOfDoneSets || props.redirectToHomepage) {
            return <Redirect to="/" />
        }        
    if (props.setSubmitted) {
        return <Redirect to="/result" />
    }
    if (props.randomizedOptions[props.currentQuestionNumber - 1]) {
        const options = []
        for(let i = 0; i < 4; i++) {
            options.push(
                    <Option
                        key={i}
                        optionNumber={i}
                        optionText={props.randomizedOptions[props.currentQuestionNumber - 1][i]}
                    />
                )
        }
        return(
            <div id="question" className="flex flex-col justify-between">
                <ProgressBar />
                <div className="md:flex md:flex-col md:items-center">
                    <div id="question-word" className="abril text-5xl pl-5 mt-10 mb-10">
                        {props.words[props.currentQuestionNumber - 1]}
                    </div>
                    <div className="md:flex md:flex-row md:m-20 md:space-x-20">
                        {options}          
                    </div>
                </div>
                <LearnFooter />
            </div>
        )
    } else {
        return <div>Retrieving set...</div>
    }
}

const mapStateToProps = state => ({
    uid: state.auth.uid,
    words: state.database.words,
    randomizedOptions: state.database.randomizedOptions,
    correctOptions: state.database.correctOptions,
    currentQuestionNumber: state.test.currentQuestionNumber,
    setSubmitted: state.database.setSubmitted,
    redirectToHomepage: state.interface.redirectToHomepage,
    numOfDoneSets: state.database.numOfDoneSets
})

const mapDispatchToProps = dispatch => ({
    startRetrieveSet: testNumber => dispatch(startRetrieveSet(testNumber)),
    getNumberOfDoneTests: (uid, callback) => {dispatch(startGetNumberOfDoneTests(uid)); callback()}
})

export default connect(mapStateToProps, mapDispatchToProps)(Question)