import { combineReducers, applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'

import authReducer from './reducers/auth'
import databaseReducer from './reducers/database'
import interfaceReducer from './reducers/interface'
import testReducer from './reducers/test'

const store = createStore(combineReducers({
    auth: authReducer,
    database: databaseReducer,
    test: testReducer,
    interface: interfaceReducer
}), compose(applyMiddleware(thunk)))

export default store