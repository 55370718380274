import React from 'react'

import { connect } from 'react-redux'

import { Link } from 'react-router-dom'



const TreeCircle = props => {
    const openTree = (
        <Link to={`/learn/${props.setNumber}`}>
            <div className="bg-gray-200 relative py-7 rounded-full">
                <p className="text-base">set</p>
                <p className="text-5xl">{props.setNumber}</p>
                <div className="absolute bottom-0 right-0">
                    {props.numOfDoneSets >= props.setNumber ? "🟢" : (props.closed ? "" : "🟠")}
                </div>
            </div>
        </Link>
    )

    const closeTree = (
        <div className="bg-gray-200 relative py-7 rounded-full cursor-default">
            <p className="text-base">set</p>
            <p className="text-5xl">{props.setNumber}</p>
            <div className="absolute bottom-0 right-0">
                {props.numOfDoneSets >= props.setNumber ? "🟢" : (props.closed ? "" : "🟠")}
            </div>
        </div>
    )
    return (
        <div className={props.closed ? "opacity-30" : ""}>
            <div className="abril text-center w-32">
                {props.closed ? closeTree : openTree}
            </div>
            {props.setNumber !== 30 ?
                (<div className="border-l-4 ml-16 my-4 h-12">

                </div>) : ""
            }
        </div>
    )
}

const mapStateToProps = state => ({
    numOfDoneSets: state.database.numOfDoneSets
})

export default connect(mapStateToProps)(TreeCircle)