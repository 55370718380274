import React from 'react'
import {  Link } from 'react-router-dom'
import { connect } from 'react-redux'


import Button from '../elements/Button'

const ProgressBar = props => {

    let undoneBars = []

    for (let i=0; i < 10 - props.userResults.length; i++) {
        undoneBars.push(<div className="w-8 h-5 rounded-lg md:w-10 md:h-10 bg-yellow-300 mr-1" key={`undoneBar-${i}`}></div>)
    }

    if (props.setSubmitted) {
        return(
            <div id="progress-bar" className="fredoka flex flex-col items-center text-center">
                <div className="flex flex-row items-center justify-center mt-3">
                    {
                        props.userResults.map((el, index) => {
                            if (el) {
                                return <Link key={`${el}${index}`} to={`/review/${index + 1}`}><div className="w-8 h-5 rounded-lg md:w-10 md:h-10 bg-green-300 mr-1" key={`correct-${index}`}>{index + 1}</div></Link>
                            } else {
                                return <Link key={`${el}${index}`} to={`/review/${index + 1}`}><div className="w-8 h-5 rounded-lg md:w-10 md:h-10 bg-red-300 mr-1" key={`wrong-${index}`}>{index + 1}</div></Link>
                            }
                        })
                    }
                </div>
                <Button
                    warningmessage="Are you done with the review?"
                    text="🏠"
                />
                {props.showResultPageNotice ? <div className="abril text-xs md:text-lg md:mt-2"><Link to="/result">👈 Go back to Result page</Link></div> : ""}
                {props.showClickSquaresNotice ? <div className="abril text-xs md:text-lg md:mt-2">👆 Use squares to navigate</div> : ""}
                
            </div>
        )
    }

    return(
        <div id="progress-bar" className="flex flex-col space-y-2">
            <div className="flex flex-row items-center justify-center mt-3">
                {
                    props.userResults.map((el, index) => {
                        if (el) {
                            return <div className="w-8 h-5 rounded-lg md:w-10 md:h-10 bg-green-300 mr-1" key={`correct-${index}`}></div>
                        } else {
                            return <div className="w-8 h-5 rounded-lg md:w-10 md:h-10 bg-red-300 mr-1" key={`wrong-${index}`}></div>
                        }
                    })
                }
                {
                    undoneBars
                }
            </div>
            <Button
                warningmessage={props.currentQuestionNumber > 7 ? "Are you sure? You're almost there." : "Are you sure?"}
                text="🏠"
            />
        </div>
    )
}

const mapStateToProps = state => ({
    currentQuestionNumber: state.test.currentQuestionNumber,
    userResults: state.test.userResults,
    setSubmitted: state.database.setSubmitted
})

export default connect(mapStateToProps)(ProgressBar)