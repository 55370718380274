import React from 'react'

import { connect } from 'react-redux'

import { checkCurrentAnswer, emptyCurrentAnswer, nextQuestion, pushAnswerToUserAnswers, pushResultToUserResults } from '../store/actions/test'
import { learnFooterButtonClicked } from '../store/actions/interface'
import { startSubmitSetToDatabase } from '../store/actions/database'

import Button from '../elements/Button'

const LearnFooter = props => {
    return(
        <div id="learn-footer" className="bg-gray-100 md:text-lg">
            <div className="flex justify-between items-center p-10 h-20 md:container md:max-w-screen-lg md:mx-auto">
                <div id="learn-footer--text" className="abril">
                    {
                        props.learnFooterButtonClicks % 2 === 0 ?
                        (
                            props.currentQuestionNumber === 10 ?
                            "Last question. Select the correct option." :
                            "Select the correct option."
                        ) :
                        (
                            props.currentAnswerResult ?
                            "Correct!" :
                            `Incorrect. Correct answer was "${props.correctOptions[props.currentQuestionNumber - 1]}"`
                        )
                        
                    }
                </div>
                <div id="learn-footer--button">
                    {
                        props.learnFooterButtonClicks % 2 === 0 ?
                        <Button
                            disabled={!props.currentAnswer}
                            color="yellow"
                            action={() => props.checkCurrentAnswer(props.correctOptions[props.currentQuestionNumber - 1])}
                            text="Check"
                        /> :
                        (
                            props.currentQuestionNumber === 10 ?
                            <Button
                                color="yellow"
                                disabled={!props.currentAnswer || props.setSubmitted}
                                action={() => props.submitSetToDatabase(props.uid, props.setRetrieved, props.userAnswers, props.userResults)}
                                text="Submit"
                            /> :
                            <Button
                                color="yellow"
                                action={props.nextQuestion}
                                text="Next"
                            />
                        )

                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    learnFooterButtonClicks: state.interface.learnFooterButtonClicked,
    currentQuestionNumber: state.test.currentQuestionNumber,
    correctOptions: state.database.correctOptions,
    currentAnswerResult: state.test.currentAnswerResult,
    currentAnswer: state.test.currentAnswer,
    uid: state.auth.uid,
    userAnswers: state.test.userAnswers,
    userResults: state.test.userResults,
    setRetrieved: state.database.setRetrieved,
    setSubmitted: state.database.setSubmitted
})

const mapDispatchToProps = dispatch => ({
    nextQuestion: () => {
        dispatch(nextQuestion())
        dispatch(learnFooterButtonClicked())
        dispatch(emptyCurrentAnswer())
    },
    checkCurrentAnswer: correctOption => {
        dispatch(checkCurrentAnswer(correctOption))
        dispatch(learnFooterButtonClicked())
        dispatch(pushAnswerToUserAnswers())
        dispatch(pushResultToUserResults())
    },
    submitSetToDatabase: (uid, setNumber, userAnswers, userResults) => {
        dispatch(startSubmitSetToDatabase(uid, setNumber, userAnswers, userResults))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LearnFooter)