import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { resetDatabaseReducer, startGetNumberOfDoneTests, startGetSetSubmitDates } from '../store/actions/database'
import { resetInterfaceReducer } from '../store/actions/interface'
import { resetTestReducer } from '../store/actions/test'

import Header from '../elements/Header'
import DailyStreak from '../elements/DailyStreak'
import TotalProgress from '../elements/TotalProgress'
import TreeCircle from '../elements/TreeCircle'
import FooterLinks from '../elements/FooterLinks'

const Learn = props => {
    const resetReducersFunction = props.resetReducers
    const uid = props.uid
    const getStreak = props.getStreak
    const getNumberOfDoneTests = props.getNumberOfDoneTests
    const numberOfDoneTests = props.numOfDoneSets

    let openTress = []
    for (let i = 1; i < props.numberOfDoneTests + 2; i++) {
        openTress.push(<TreeCircle key={`treecircle-${i}`} setNumber={i}/>)
    }
    let closedTrees = []
    for (let i = 1; props.numberOfDoneTests + i + 1 < 31; i++) {
        closedTrees.push(<TreeCircle key={`treecircle-${i}`} closed setNumber={props.numberOfDoneTests + 1 + i}/>)
    }

    useEffect(() => {
        resetReducersFunction()
        getStreak(uid)
        if (!numberOfDoneTests) {
            getNumberOfDoneTests(uid)
        }
    }, [resetReducersFunction, uid, getStreak, getNumberOfDoneTests, numberOfDoneTests])

    return(
        <div
            id="learn"
            className="flex flex-col mb-20"
        >
            <div className="bg-gray-100 items-center pb-10">
                <div
                    className="container max-w-screen-lg mx-auto px-3 sm:pl-5 md:pl-10 l:pl-0 text-2xl"
                >
                    <Header />
                </div>

            </div>
            <div
                className="container max-w-screen-md mx-auto items-center md:items-baseline flex flex-col-reverse mt-10 md:mt-0 md:flex-row pl-3 md:mt-20 sm:pl-5 md:pl-10 l:pl-0 text-2xl"
            >
                <div id="tree" className="w-2/3 flex flex-col items-center md:items-baseline">
                    {openTress}
                    {closedTrees}
                </div>
                <div id="sidebar" className="w-1/3 md:pl-16 md:border-l-2">
                    <TotalProgress />
                    <DailyStreak />
                    <FooterLinks />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    uid: state.auth.uid,
    numberOfDoneTests: state.database.numOfDoneSets
})

const mapDispatchToProps = dispatch => ({
    getStreak: uid => dispatch(startGetSetSubmitDates(uid)),
    getNumberOfDoneTests: uid => {dispatch(startGetNumberOfDoneTests(uid))},
    resetReducers: () => {
        dispatch(resetTestReducer())
        dispatch(resetDatabaseReducer())
        dispatch(resetInterfaceReducer())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Learn)