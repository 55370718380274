import React from 'react'
import ReactDOM from 'react-dom'
import './style/index.css'

import firebase from './services/firebase'

import { Provider } from 'react-redux'

import store from './store'
import { loginUser, logoutUser } from './store/actions/auth'

import App from './App'

const rootElement = document.getElementById('root')

ReactDOM.render(
    <div>Loading...</div>, rootElement)

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        store.dispatch(loginUser(user.uid))
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>, rootElement)
        
    } else {
        store.dispatch(logoutUser())
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>, rootElement)
    }
})