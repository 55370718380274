import { CHECK_CURRENT_ANSWER, EMPTY_CURRENT_ANSWER, NEXT_QUESTION, PUSH_ANSWER_TO_USER_ANSWERS, PUSH_RESULT_TO_USER_RESULTS, RESET_TEST_REDUCER, SAVE_CURRENT_ANSWER } from '../types'

const initialState = {
    currentQuestionNumber: 1,
    currentAnswer: '',
    currentAnswerResult: '',
    userAnswers: [],
    userResults: []
}

const databaseReducer = (state=initialState, action) => {
    switch (action.type) {
        case NEXT_QUESTION:
            return {
                ...state,
                currentQuestionNumber: state.currentQuestionNumber + 1
            }
        case SAVE_CURRENT_ANSWER:
            return {
                ...state,
                currentAnswer: action.answer
            }
        case EMPTY_CURRENT_ANSWER:
            return {
                ...state,
                currentAnswer: ''
            }
        case CHECK_CURRENT_ANSWER:
            return {
                ...state,
                currentAnswerResult: state.currentAnswer === action.correctOption
            }
        case PUSH_ANSWER_TO_USER_ANSWERS:
            return {
                ...state,
                userAnswers: [...state.userAnswers, state.currentAnswer]
            }
        case PUSH_RESULT_TO_USER_RESULTS:
            return {
                ...state,
                userResults: [...state.userResults, state.currentAnswerResult]
            }
        case RESET_TEST_REDUCER:
            return initialState
        default:
            return state
    }
}

export default databaseReducer