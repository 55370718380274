import React from 'react'
import { connect } from 'react-redux'

const TotalProgress = props => {
    return(
        <div id="total-progress" className="text-center">
            <div className="border-b-2 pb-10">
                <p className="fredoka text-6xl">{parseInt(props.numOfDoneSets * 100 / 30)}%</p>
                <p className="abril text-base">COMPLETED</p>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    numOfDoneSets: state.database.numOfDoneSets
})

export default connect(mapStateToProps)(TotalProgress)