import { LEARN_FOOTER_BUTTON_CLICKED, RESET_INTERFACE_REDUCER, TOGGLE_REDIRECT_TO_HOMEPAGE } from '../types'

const initialState = {
    learnFooterButtonClicked: 0,
    redirectToHomepage: false

}

const interfaceReducer = (state=initialState, action) => {
    switch (action.type) {
        case LEARN_FOOTER_BUTTON_CLICKED:
            return {
                ...state,
                learnFooterButtonClicked: state.learnFooterButtonClicked + 1
            }
        case TOGGLE_REDIRECT_TO_HOMEPAGE:
            return {
                ...state,
                redirectToHomepage: !state.redirectToHomepage
            }
        case RESET_INTERFACE_REDUCER:
            return initialState
        default:
            return state
    }
}

export default interfaceReducer