import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";

import PrivateRoute from './routers/PrivateRoute';
import PublicRoute from './routers/PublicRoute';


import Home from './components/Home'
import Learn from './components/Learn'
import Question from './components/Question';
import Result from './components/Result'
import Review from './components/Review'

const App = () => {
    return(
        <Router>
            <Switch>
                <PublicRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/learn" component={Learn} />
                <PrivateRoute exact path="/learn/:id(10|20|30|[1-2]?[1-9])" component={Question} />
                <PrivateRoute exact path="/result" component={Result} />
                <PrivateRoute exact path="/review/:id(10|[1-9])" component={Review} />
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Router>
    )
}

export default App