import { database } from '../../services/firebase'

import { SET_STREAK, RESET_DATABASE_REDUCER, RETRIEVE_SET, SET_SET, SUBMIT_SET_TO_DATABASE, SET_NUMBER_OF_DONE_TESTS } from '../types'

import extractCorrectOptions from '../../workers/extractCorrectOptions'
import randomizeOptions from '../../workers/randomizeOptions'
import getWords from '../../workers/getWords'

export const retrieveSet = setNumber => {
    return {
        type: RETRIEVE_SET,
        setNumber
    }
}

export const setSet = (words, randomizedOptions, correctOptions) => {
    return {
        type: SET_SET,
        words,
        randomizedOptions,
        correctOptions
    }
}

export const startRetrieveSet = setNumber => {
    return dispatch => {
        return database.ref('sets').once('value').then(
            snapshot => {
                const questions = snapshot.child(setNumber).val()

                const words = getWords(questions)

                const correctOptions = extractCorrectOptions(questions)

                const randomizedOptions = randomizeOptions(questions)

                dispatch(retrieveSet(setNumber + 1))
                dispatch(setSet(words, randomizedOptions, correctOptions))
            }
          )
    }
}

export const submitSetToDatabase = () => ({
    type: SUBMIT_SET_TO_DATABASE
})

export const startSubmitSetToDatabase = (uid, setNumber, userAnswers, userResults) => {
    var d = new Date();
    var n = d.getTime();
    return dispatch => {
        return database.ref(`users/${uid}/sets/${setNumber}/${n}`).set({
            userAnswers,
            userResults
        }).then(
            database.ref(`users/${uid}/meta/setSubmitDates/`).push({"timestamp": -n})
        ).then(dispatch(submitSetToDatabase()))
    }
}

const setStreak = streakNum => ({
    type: SET_STREAK,
    streakNum
})

export const startGetSetSubmitDates = uid => {
    return dispatch => {
        database.ref(`users/${uid}/meta/setSubmitDates`).orderByChild('timestamp').once('value')
        .then(snapshot => {
            let setSubmitDates = []
            let i = 0
            const today = new Date()
            const todayDate = today.getDate()
            const currentYear = today.getUTCFullYear()
            const currentMonth = today.getUTCMonth()

            snapshot.forEach(childSnapshot => {
                const childVal = -childSnapshot.val().timestamp
                const date = new Date(childVal)
                const retrievedDay = date.getDate()
                const retrievedMonth = date.getUTCMonth()
                const retrievedYear = date.getUTCFullYear()
                
                if (retrievedYear === currentYear && retrievedMonth === currentMonth) {
                    if (todayDate - retrievedDay <= 1) {
                        if (todayDate - retrievedDay === 0) {
                            return false 
                        } else if (setSubmitDates[i - 1] - retrievedDay === 0) {
                            return false
                        } else {
                            setSubmitDates.push(retrievedDay)
                        }
                    } else if (setSubmitDates[i - 1] - retrievedDay === 1) {
                        setSubmitDates.push(retrievedDay)
                    } else {
                        return true
                    }
                } else {
                    return true
                }

                i++
            })

            return setSubmitDates.length
        }).then(streakNum =>
            dispatch(setStreak(streakNum))
        )
    }
}

const setNumberOfDoneTests = numOfDoneSets => ({
    type: SET_NUMBER_OF_DONE_TESTS,
    numOfDoneSets
})

export const startGetNumberOfDoneTests = uid => {
    return dispatch => {
        database.ref(`users/${uid}/sets`).once('value')
        .then(snapshot => {
            return snapshot.numChildren()
        })
        .then(numOfDoneSets => {
            dispatch(setNumberOfDoneTests(numOfDoneSets))
        })
    }
}

export const resetDatabaseReducer = () => ({
    type: RESET_DATABASE_REDUCER
})