import React from 'react'

const FooterLinks = () => {
    return(
        <div className="pt-10 opacity-50 text-center text-sm mb-10 pb-10 md:mb-10 md:pb-0 border-b-2 md:border-b-0 hidden md:block">
            This website is not endorsed or approved by ETS, who owns TOEFL. This website is being tested at the moment. Follow for quality content: <a target="_blank" rel="noreferrer" href="https://instagram.com/TOEFLProfesoru">@TOEFLProfesoru</a>.
        </div>
    )
}

export default FooterLinks