const getUserCorrectAndWrongAnswers = (userAnswers, userResults) => {
    let userCorrectAnswers = []
    let userWrongAnswers = []

    for (let i = 0; i <10; i++) {
        if (userAnswers.length === 10) {
            if (userResults[i]) {
                userCorrectAnswers.push(userAnswers[i])
            } else {
                userWrongAnswers.push(userAnswers[i])
            }
        }
    }

    return [ userCorrectAnswers, userWrongAnswers ]
}

export default getUserCorrectAndWrongAnswers