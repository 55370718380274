import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from '../elements/Header'
import Button from '../elements/Button'

import { startLoginUser } from '../store/actions/auth'


const Home = props => {

    if (!!props.userLoggedIn) {
        return <Redirect to="/learn" />
    }

    return(
        <div
            id="main"
            className="flex flex-col"
        >
            <div
                className="container max-w-screen-lg mx-auto pl-3 sm:pl-5 md:pl-10 l:pl-0 space-y-36 sm:space-y-40 text-2xl"
            >
                <Header homePage />
                <div 
                    id="hero-text"
                    className="abril pt-20 md:pt-80"
                >
                    <p className="text-sm sm:text-2xl">Learn All The Words You Need.</p>
                    <p className="text-4xl md:text-5xl leading-snug">Get <span className="border-b-2 border-green-300">The Best TOEFL Result</span> You Can.</p>
                </div>
                <div className="flex justify-center">
                    <Button action={props.startLoginUser} color="green" text="Start Learning" />
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    startLoginUser: () => dispatch(startLoginUser())
})

export default connect(undefined, mapDispatchToProps)(Home)