import React from 'react'
import { connect } from 'react-redux'

const DailyStreak = props => {
    return(
        <div id="daily-streak" className="pt-10 text-center text-lg mb-10 pb-10 md:mb-0 border-b-2">
            <p className="abril">Daily Streak: {props.streak}🔥</p>
        </div>
    )
}

const mapStateToProps = state => ({
    streak: state.database.streakNum
})

export default connect(mapStateToProps)(DailyStreak)