import store from '../store'
import { toggleRedirectToHomepage } from '../store/actions/interface'

const askForConfirmation = warningMessage => {
    const result = window.confirm(warningMessage)
    if(result) {
        store.dispatch(toggleRedirectToHomepage())
    }
}

export default askForConfirmation