import React from 'react'

import askForConfirmation from '../workers/askForConfirmation'

const Button = props => {
    return(
        <button
            disabled={props.disabled}
            onClick={
                props.warningmessage ?
                () => askForConfirmation(props.warningmessage) :
                props.action
            }
            className={`fredoka focus:outline-none ${props.text === "X" ? "ml-3" : "px-5"} ${props.small ? "py-1 text-base" : "py-2"} rounded-xl bg-${props.color}-${props.disabled ? "200" : "300"}`}
        >
            {props.text}
        </button>
    )
}

export default Button