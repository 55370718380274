import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import ProgressBar from '../elements/ProgressBar'

import calculateUserResultPercentage from '../workers/calculateUserResultPercentage'
import getUserCorrectAndWrongAnswers from '../workers/getUserCorrectAndWrongAnswers'

const Result = props => {

    if (props.userAnswers.length === 0 || props.redirectToHomepage) {
        return <Redirect to="/" />
    }

    const userCorrectAnswers = getUserCorrectAndWrongAnswers(props.userAnswers, props.userResults)[0]
    const userWrongAnswers = getUserCorrectAndWrongAnswers(props.userAnswers, props.userResults)[1]

    return(
        <div id="result" className="flex flex-col items-center space-y-10 md:space-y-20">
            <ProgressBar showClickSquaresNotice />
            <div id="result--percentage" className="text-center w-screen bg-gray-200 py-5 md:py-16">
                <p className="abril">
                    YOUR RESULT
                </p>
                <p className="fredoka text-8xl">
                    {calculateUserResultPercentage(props.userResults)}%
                </p>
            </div>
            <div id="result--word" className="flex flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-28">
                <div id="result--correct-answer" className="fredoka">
                    <p className="abril text-xl md:text-2xl mb-5 border-b-2 border-green-300">✅ Correct Answers</p>
                    {
                        userCorrectAnswers.length > 0 ?
                        userCorrectAnswers.map(word => {
                            return (
                                    <li key={`wrongword-${word}`} className="mb-1 text-lg">
                                        <Link to={`/review/${props.userAnswers.indexOf(word) + 1}`}>{word}</Link>
                                    </li>
                                )
                            }
                        ) :
                        <span className="text-gray-400">😟 Maybe next time?</span>
                    }
                </div>
                <div id="result--wrong-answer" className="fredoka">
                    <p className="abril text-lg md:text-2xl mb-5 border-b-2 border-red-300">❌ Wrong Answers</p>
                    {
                        userWrongAnswers.length > 0 ?
                        userWrongAnswers.map(word => {
                            return (
                                    <li key={`wrongword-${word}`} className="mb-1 text-lg">
                                        <Link to={`/review/${props.userAnswers.indexOf(word) + 1}`}>{word}</Link>
                                    </li>
                                )
                            }
                        ) :
                        <span className="text-gray-400">🔥 None, zero, nada</span>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    userAnswers: state.test.userAnswers,
    userResults: state.test.userResults,
    redirectToHomepage: state.interface.redirectToHomepage
})

export default connect(mapStateToProps)(Result)