import React from 'react'

import { connect } from 'react-redux'
import { saveCurrentAnswer } from '../store/actions/test'

const Option = props => {    
    return(
        <div 
            id={`option-${props.optionNumber}`}
            className={`fredoka rounded-lg md:w-48 md:h-28 md:text-xl md:flex md:items-center md:justify-center md:text-center ${props.reviewMode ? "" : "cursor-pointer"} text-2xl mb-5 p-5 ${props.reviewMode ? (props.optionText === props.userAnswers[props.reviewQuestionNumber - 1] ? (props.userAnswers[props.reviewQuestionNumber - 1] === props.correctOptions[props.reviewQuestionNumber - 1] ? "bg-green-200" : "bg-red-200") : "bg-gray-200") : (props.learnFooterButtonClicked % 2 === 0 ? (props.currentAnswer === props.optionText ? "bg-yellow-200" : "bg-gray-200") : (props.currentAnswerResult && props.currentAnswer === props.optionText ? "bg-green-200" : (!props.currentAnswerResult && props.currentAnswer === props.optionText ? "bg-red-200" : "bg-gray-200")))}`}
            onClick={props.reviewMode || props.learnFooterButtonClicked % 2 === 1 ? null : () => props.saveCurrentAnswer(props.optionText)}
        >
            {props.optionText}
        </div>
    )
}

const mapStateToProps = state => ({
    currentAnswer: state.test.currentAnswer,
    learnFooterButtonClicked: state.interface.learnFooterButtonClicked,
    currentAnswerResult: state.test.currentAnswerResult,
    userAnswers: state.test.userAnswers,
    correctOptions: state.database.correctOptions,
    reviewMode: state.database.setSubmitted
})

const mapDispatchToProps = dispatch => ({
    saveCurrentAnswer: answer => dispatch(saveCurrentAnswer(answer))
})

export default connect(mapStateToProps, mapDispatchToProps)(Option)