import { LEARN_FOOTER_BUTTON_CLICKED, RESET_INTERFACE_REDUCER, TOGGLE_REDIRECT_TO_HOMEPAGE } from '../types'

export const learnFooterButtonClicked = () => {
    return {
        type: LEARN_FOOTER_BUTTON_CLICKED
    }
}

export const toggleRedirectToHomepage = () => {
    return {
        type: TOGGLE_REDIRECT_TO_HOMEPAGE
    }
}

export const resetInterfaceReducer = () => ({
    type: RESET_INTERFACE_REDUCER
})