import { CHECK_CURRENT_ANSWER, EMPTY_CURRENT_ANSWER, NEXT_QUESTION, PUSH_ANSWER_TO_USER_ANSWERS, PUSH_RESULT_TO_USER_RESULTS, RESET_TEST_REDUCER, SAVE_CURRENT_ANSWER } from '../types'

export const nextQuestion = () => {
    return {
        type: NEXT_QUESTION
    }
}

export const saveCurrentAnswer = answer => {
    return {
        type: SAVE_CURRENT_ANSWER,
        answer
    }
}

export const emptyCurrentAnswer = () => {
    return {
        type: EMPTY_CURRENT_ANSWER
    }
}

export const checkCurrentAnswer = correctOption => {
    return {
        type: CHECK_CURRENT_ANSWER,
        correctOption
    }
}

export const pushAnswerToUserAnswers = () => {
    return {
        type: PUSH_ANSWER_TO_USER_ANSWERS
    }
}

export const pushResultToUserResults = () => {
    return {
        type: PUSH_RESULT_TO_USER_RESULTS
    }
}

export const resetTestReducer = () => ({
    type: RESET_TEST_REDUCER
})