import { SET_STREAK, RESET_DATABASE_REDUCER, RETRIEVE_SET, SET_SET, SUBMIT_SET_TO_DATABASE, SET_NUMBER_OF_DONE_TESTS } from '../types'

const initialState = {
    setRetrieved: 0,
    words: [],
    randomizedOptions: [],
    correctOptions: [],
    streakNum: 0,
    numOfDoneSets: 0,
    setSubmitted: false
}

const databaseReducer = (state=initialState, action) => {
    switch (action.type) {
        case RETRIEVE_SET:
            return {
                ...state,
                setRetrieved: action.setNumber
            }
        case SET_SET:
            return {
                ...state,
                words: action.words,
                randomizedOptions: action.randomizedOptions,
                correctOptions: action.correctOptions
            }
        case SUBMIT_SET_TO_DATABASE:
            return {
                ...state,
                setSubmitted: true
            }
        case SET_STREAK:
            return {
                ...state,
                streakNum: action.streakNum
            }
        case SET_NUMBER_OF_DONE_TESTS:
            return {
                ...state,
                numOfDoneSets: action.numOfDoneSets
            }
        case RESET_DATABASE_REDUCER:
            return initialState
        default:
            return state
    }
}

export default databaseReducer