import firebase, { googleProvider } from '../../services/firebase'

import { LOGIN_USER, LOGOUT_USER } from '../types'

export const startLoginUser = () => {
    return () => {
        return firebase.auth().signInWithPopup(googleProvider)
    }
}

export const loginUser = uid => {
    return {
        type: LOGIN_USER,
        uid
    }
}

export const startLogoutUser = () => {
    return () => {
        return firebase.auth().signOut()
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT_USER
    }
}