import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import ProgressBar from '../elements/ProgressBar'
import Option from '../elements/Option'
import ReviewFooter from '../elements/ReviewFooter'

const Review = props => {
    const questionNumber = props.match.params.id

    if (props.redirectToHomepage) {
        return <Redirect to="/" />
    }

    if (props.setSubmitted && props.randomizedOptions[questionNumber - 1]) {
        const options = []
        for(let i = 0; i < 4; i++) {
            options.push(
                    <Option
                        reviewQuestionNumber={questionNumber}
                        key={i}
                        optionNumber={i}
                        optionText={props.randomizedOptions[questionNumber - 1][i]}
                    />
                )
        }
        return(
            <div id="review" className="flex flex-col justify-between">
                <ProgressBar showResultPageNotice />
                <div className="md:flex md:flex-col md:items-center">
                    <div id="review-word" className="abril text-5xl pl-5 mt-10 mb-10">
                        {props.words[questionNumber - 1]}
                    </div>
                    <div className="md:flex md:flex-row md:m-20 md:space-x-20">
                        {options}          
                    </div>
                </div>
                <ReviewFooter
                    correctAnswer={props.correctOptions[questionNumber - 1]}
                    result={props.userResults[questionNumber - 1]}
                />
            </div>
        )
    } else {
        return <Redirect to="/" />
    }
}

const mapStateToProps = state => ({
    words: state.database.words,
    randomizedOptions: state.database.randomizedOptions,
    correctOptions: state.database.correctOptions,
    currentQuestionNumber: state.test.currentQuestionNumber,
    userAnswers: state.test.userAnswers,
    userResults: state.test.userResults,
    setSubmitted: state.database.setSubmitted,
    redirectToHomepage: state.interface.redirectToHomepage
})

export default connect(mapStateToProps)(Review)