export const START_LOGIN_USER = 'START_LOGIN_USER'
export const START_LOGOUT_USER = 'START_LOGOUT_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'

export const RETRIEVE_SET = 'RETRIEVE_SET'
export const SET_SET = 'SET_SET'
export const SUBMIT_SET_TO_DATABASE = 'SUBMIT_SET_TO_DATABASE'
export const SET_STREAK = 'SET_STREAK'
export const SET_NUMBER_OF_DONE_TESTS = 'SET_NUMBER_OF_DONE_TEST'
export const RESET_DATABASE_REDUCER = 'RESET_DATABASE_REDUCER'

export const NEXT_QUESTION = 'NEXT_QUESTION'
export const SAVE_CURRENT_ANSWER = 'SAVE_CURRENT_ANSWER'
export const EMPTY_CURRENT_ANSWER = 'EMPTY_CURRENT_ANSWER'
export const CHECK_CURRENT_ANSWER = 'CHECK_CURRENT_ANSWER'
export const PUSH_ANSWER_TO_USER_ANSWERS = 'PUSH_ANSWER_TO_USER_ANSWERS'
export const PUSH_RESULT_TO_USER_RESULTS = 'PUSH_RESULT_TO_USER_RESULTS'
export const RESET_TEST_REDUCER = 'RESET_TEST_REDUCER'

export const LEARN_FOOTER_BUTTON_CLICKED = 'LEARN_FOOTER_BUTTON_CLICKED'
export const TOGGLE_REDIRECT_TO_HOMEPAGE = 'TOGGLE_REDIRECT_TO_HOMEPAGE'
export const RESET_INTERFACE_REDUCER = 'RESET_INTERFACE_REDUCER'